const firebaseConfig = {
  apiKey: "AIzaSyCvlKA_l52pB68iU19mJKAa173CnzcrNKI",
  authDomain: "all-star-wellness.firebaseapp.com",
  databaseURL: "https://all-star-wellness.firebaseio.com",
  projectId: "all-star-wellness",
  storageBucket: "all-star-wellness.appspot.com",
  messagingSenderId: "767471438808",
};

export default firebaseConfig;
