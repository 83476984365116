import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";

import { setActiveTab } from "../../redux/actions";

//Import Images
import logo from "../../assets/images/logo.png";

function LeftSidebarMenu(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    const userData = localStorage.getItem("authUser");
    const user = JSON.parse(userData);
    setUser(user);
  }, []);

  const toggle = () => setDropdownOpen(!dropdownOpen);
  const toggleMobile = () => setDropdownOpenMobile(!dropdownOpenMobile);

  const toggleTab = (tab) => {
    props.setActiveTab(tab);
  };

  const activeTab = props.activeTab;

  return (
    <React.Fragment>
      <div className="side-menu flex-lg-column mr-lg-1">
        {/* LOGO */}
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="logo" height="30" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logo} alt="logo" height="30" />
            </span>
          </Link>
        </div>
        {/* end navbar-brand-box  */}

        {/* Start side-menu nav */}
        <div className="flex-lg-column my-auto">
          <Nav
            pills
            className="side-menu-nav justify-content-center"
            role="tablist"
          >
            <NavItem id="profile">
              <NavLink
                id="pills-user-tab"
                className={classnames({ active: activeTab === "profile" })}
                onClick={() => {
                  toggleTab("profile");
                }}
              >
                <i className="ri-user-2-line"></i>
              </NavLink>
            </NavItem>

            <UncontrolledTooltip target="profile" placement="top">
              Profile
            </UncontrolledTooltip>
            <NavItem id="Chats">
              <NavLink
                id="pills-chat-tab"
                className={classnames({ active: activeTab === "chat" })}
                onClick={() => {
                  toggleTab("chat");
                }}
              >
                <i className="ri-message-3-line"></i>
              </NavLink>
            </NavItem>

            <Dropdown
              nav
              isOpen={dropdownOpenMobile}
              toggle={toggleMobile}
              className="profile-user-dropdown d-inline-block d-lg-none"
            >
              <DropdownToggle nav>
                <img
                  src={user?.photoURL}
                  alt=""
                  className="profile-user rounded-circle"
                />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem href="/logout">
                  Log out{" "}
                  <i className="ri-logout-circle-r-line float-right text-muted"></i>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
        {/* end side-menu nav */}

        <div className="flex-lg-column d-none d-lg-block">
          <Nav className="side-menu-nav justify-content-center">
            <Dropdown
              nav
              isOpen={dropdownOpen}
              className="btn-group dropup profile-user-dropdown"
              toggle={toggle}
            >
              <DropdownToggle nav>
                <img
                  src={user?.photoURL}
                  alt=""
                  className="profile-user rounded-circle"
                />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem href="/logout">
                  Log out{" "}
                  <i className="ri-logout-circle-r-line float-right text-muted"></i>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
        {/* Side menu user */}
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};

export default connect(mapStatetoProps, {
  setActiveTab,
})(LeftSidebarMenu);
