import { firebaseService } from "../../helpers/firebase";
import {
  CHAT_USER,
  ACTIVE_USER,
  FULL_USER,
  ADD_LOGGED_USER,
  CREATE_GROUP,
  CHAT_LOAD_USERS_SUCCESS,
  CHAT_LOAD_USERS_ERROR,
  CHAT_LOADING_MESSAGES,
  CHAT_LOAD_MESSAGES_SUCCESS,
  CHAT_LOAD_MESSAGES_ERROR,
  CHAT_MESSAGE_LOADING,
  CHAT_MESSAGE_SUCCESS,
  CHAT_MESSAGE_ERROR,
  CHAT_MESSAGE_UPDATE,
} from "./constants";
import { loadMessagesMapper } from "../../helpers/Mapper";

const FIREBASE_REF_SERVICES = firebaseService
  .database()
  .ref("Configuration/services");
const FIREBASE_REF_USERS = firebaseService.database().ref("Users");

export const chatUser = () => ({
  type: CHAT_USER,
});

export const activeUser = (userId) => ({
  type: ACTIVE_USER,
  payload: userId,
});

export const setFullUser = (fullUser) => ({
  type: FULL_USER,
  payload: fullUser,
});

export const addLoggedinUser = (userData) => ({
  type: ADD_LOGGED_USER,
  payload: userData,
});

export const createGroup = (groupData) => ({
  type: CREATE_GROUP,
  payload: groupData,
});

export const loadUsers = ({ orderBy }) => (dispatch) => {
  return FIREBASE_REF_USERS.orderByChild("role")
    .equalTo(orderBy)
    .once("value")
    .then((snapshot) => {
      const response = snapshot.val();
      dispatch(loadUsersSuccess(response));
      return response;
    })
    .catch((error) => {
      dispatch(loadUsersError(error));
      return error;
    });
};

export const getFirebaseUser = ({ uid }) => {
  return new Promise((resolve, reject) =>
    FIREBASE_REF_USERS.child(uid).on(
      "value",
      (snapshot) => {
        resolve(snapshot.val());
      },
      (errorObject) => {
        reject(errorObject.message);
      }
    )
  );
};

export const loadMessages = ({ receptorId }) => (dispatch) => {
  dispatch(loadingMessages());

  const userData = localStorage.getItem("authUser");
  const mainId = JSON.parse(userData)?.uid;

  return new Promise((resolve, reject) =>
    FIREBASE_REF_USERS.child(`${mainId}/Messages/${receptorId}`).on(
      "value",
      (snapshot) => {
        const response = snapshot.val();
        const messages = loadMessagesMapper({
          messages: response,
          uid: mainId,
        });
        dispatch(loadMessagesSuccess(messages));
        resolve(messages);
      },
      (errorObject) => {
        dispatch(loadMessagesError(errorObject.message));
        reject(errorObject.message);
      }
    )
  );
};

const generateRandomMessageId = () => Math.round(Math.random() * 1000000);

const sendRecordedMessage = ({ mainId, receptorId, chatMessage, dispatch }) => {
  // Store the messages on the main user
  FIREBASE_REF_USERS.child(`${mainId}/Messages/${receptorId}`)
    .push()
    .set(chatMessage, (error) => {
      if (error) {
        dispatch(chatMessageError(error.message));
      } else {
        dispatch(chatMessageSuccess());
      }
    });

  // Store the messages on the receptor user
  FIREBASE_REF_USERS.child(`${receptorId}/Messages/${mainId}`)
    .push()
    .set(chatMessage, (error) => {
      if (error) {
        dispatch(chatMessageError(error.message));
      } else {
        dispatch(chatMessageSuccess());
      }
    });
};

export const sendMessage = ({ message, user, receptorData }) => (dispatch) => {
  dispatch(chatMessageLoading());
  const { id: receptorId } = receptorData;
  const { uid: mainId, email, displayName, photoURL } = user;
  const createdAt = new Date().getTime();

  const chatMessage = {
    _id: generateRandomMessageId(),
    text: message,
    createdAt,
    user: {
      _id: mainId,
      email,
      name: displayName,
      avatar: photoURL,
    },
    sent: true,
    received: false,
  };

  sendRecordedMessage({ mainId, receptorId, chatMessage, dispatch });
};

export const updateMessage = (text) => (dispatch) => {
  dispatch(chatUpdateMessage(text));
};

export const sendPushNotification = (payload) =>
  FIREBASE_REF_SERVICES.child("push").on("value", (snapshot) => {
    fetch(snapshot.val(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...payload,
      }),
    });
  });

// MARK - LOAD USERS

const loadUsersSuccess = (users) => ({
  type: CHAT_LOAD_USERS_SUCCESS,
  usersList: users,
});

const loadUsersError = (error) => ({
  type: CHAT_LOAD_USERS_ERROR,
  error,
});

// MARK - LOAD MESSAGES

const loadingMessages = () => ({
  type: CHAT_LOADING_MESSAGES,
});

const loadMessagesSuccess = (messages) => ({
  type: CHAT_LOAD_MESSAGES_SUCCESS,
  messages,
});

const loadMessagesError = (error) => ({
  type: CHAT_LOAD_MESSAGES_ERROR,
  error,
});

// MARK - SEND MESSAGES

const chatMessageLoading = () => ({
  type: CHAT_MESSAGE_LOADING,
});

const chatMessageSuccess = () => ({
  type: CHAT_MESSAGE_SUCCESS,
});

const chatMessageError = (error) => ({
  type: CHAT_MESSAGE_ERROR,
  error,
});

const chatUpdateMessage = (text) => ({
  type: CHAT_MESSAGE_UPDATE,
  text,
});
