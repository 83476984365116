export const CHAT_USER = "CHAT_USER";
export const ACTIVE_USER = "ACTIVE_USER";
export const FULL_USER = "FULL_USER";
export const ADD_LOGGED_USER = "ADD_LOGGED_USER";
export const CREATE_GROUP = "CREATE_GROUP";

// MARK - LOAD USERS
export const CHAT_LOAD_USERS_SUCCESS = "CHAT_LOAD_USERS_SUCCESS";
export const CHAT_LOAD_USERS_ERROR = "CHAT_LOAD_USERS_ERROR";

// MARK - LOAD MESSAGES
export const CHAT_LOADING_MESSAGES = "CHAT_LOADING_MESSAGES";
export const CHAT_LOAD_MESSAGES_SUCCESS = "CHAT_LOAD_MESSAGES_SUCCESS";
export const CHAT_LOAD_MESSAGES_ERROR = "CHAT_LOAD_MESSAGES_ERROR";

// MARK - SEND MESSAGE

export const CHAT_MESSAGE_LOADING = "CHAT_MESSAGE_LOADING";
export const CHAT_MESSAGE_SUCCESS = "CHAT_MESSAGE_SUCCESS";
export const CHAT_MESSAGE_ERROR = "CHAT_MESSAGE_ERROR";
export const CHAT_MESSAGE_UPDATE = "CHAT_MESSAGE_UPDATE";
