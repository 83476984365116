import React from "react";
import Routes from "./routes/";

//Import Scss
import "./assets/scss/themes.scss";

//fackbackend
// import fakeBackend from "./helpers/fake-backend";

// //Firebase helper
import { initFirebaseBackend } from "./helpers/firebase";

// TODO
// fakeBackend();

// // init firebase backend
initFirebaseBackend();

function App() {
  return <Routes />;
}

export default App;
