import {
  CHAT_USER,
  ACTIVE_USER,
  FULL_USER,
  ADD_LOGGED_USER,
  CREATE_GROUP,
  CHAT_LOAD_USERS_SUCCESS,
  CHAT_LOAD_USERS_ERROR,
  CHAT_LOADING_MESSAGES,
  CHAT_LOAD_MESSAGES_SUCCESS,
  CHAT_LOAD_MESSAGES_ERROR,
  CHAT_MESSAGE_LOADING,
  CHAT_MESSAGE_SUCCESS,
  CHAT_MESSAGE_ERROR,
  CHAT_MESSAGE_UPDATE,
} from "./constants";

const INIT_STATE = {
  active_user: 0,
  users: [],
  groups: [],
  contacts: [],
  sending: false,
  sendingError: null,
  message: "",
  usersList: {},
  loadUsersError: null,
  loadingMessages: false,
  messagesList: [],
  loadMessagesError: null,
};

const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHAT_USER:
      return { ...state };

    case ACTIVE_USER:
      return {
        ...state,
        active_user: action.payload,
      };

    case FULL_USER:
      return {
        ...state,
        users: action.payload,
      };

    case ADD_LOGGED_USER:
      const newUser = action.payload;
      return {
        ...state,
        users: [...state.users, newUser],
      };

    case CREATE_GROUP:
      const newGroup = action.payload;
      return {
        ...state,
        groups: [...state.groups, newGroup],
      };

    // MARK - SEND MESSAGE
    case CHAT_MESSAGE_LOADING:
      return { ...state, sending: true, sendingError: null };
    case CHAT_MESSAGE_ERROR:
      return { ...state, sending: false, sendingError: action.error };
    case CHAT_MESSAGE_SUCCESS:
      return { ...state, sending: false, sendingError: null, message: "" };
    case CHAT_MESSAGE_UPDATE:
      return {
        ...state,
        sending: false,
        message: action.text,
        sendingError: null,
      };

    // MARK - LOAD USERS
    case CHAT_LOAD_USERS_SUCCESS:
      return { ...state, usersList: action.usersList, loadUsersError: null };

    case CHAT_LOAD_USERS_ERROR:
      return { ...state, usersList: null, loadUsersError: action.error };

    // MARK - LOAD MESSAGES
    case CHAT_LOADING_MESSAGES:
      return { ...state, loadingMessages: true, loadMessagesError: null };
    case CHAT_LOAD_MESSAGES_SUCCESS:
      return {
        ...state,
        loadingMessages: false,
        messagesList: action.messages,
        loadMessagesError: null,
      };
    case CHAT_LOAD_MESSAGES_ERROR:
      return {
        ...state,
        loadingMessages: false,
        messagesList: null,
        loadMessagesError: action.error,
      };

    default:
      return { ...state };
  }
};

export default Chat;
