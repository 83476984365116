export const getFilteredUsers = ({ users, uid }) => {
  return users
    ? [...Object.values(users)]
        .filter(({ id }) => id !== uid)
        .sort(function (a, b) {
          return a?.name?.localeCompare(b?.name);
        })
    : [];
};

export const getFilteredMessages = ({ messages }) => {
  return messages ? [...Object.values(messages)] : [];
};

export const loadMessagesMapper = ({ messages, uid }) => {
  const messageList = getFilteredMessages({ messages }).map((msg) => {
    return {
      id: msg?._id,
      message: msg?.text,
      time: msg?.createdAt,
      userType: uid === msg?.user?._id ? "sender" : "receiver",
      isImageMessage: false,
      isFileMessage: false,
      ...msg,
    };
  });

  return messageList;
};

export const loadUsersMapper = ({ users, uid }) => {
  return getFilteredUsers({ users, uid })?.map((user) => {
    return {
      id: user?.id,
      name: user?.name,
      email: user?.email,
      profilePicture: user?.avatar,
      status: "online",
      unRead: 0,
      roomType: "contact",
      isGroup: false,
      isTyping: false,
      messages: user?.Messages,
    };
  });
};
